import React from 'react';

import CaseStudiesList from '../components/CaseStudy/CaseStudiesList';

const CaseStudiesPage = () => {
  return (
    <>
      <div className="th-hero-page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="th-hero-content">
                <h1 className="th-hero-content__header">Case Studies</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CaseStudiesList caseStudyTags={[]} />
    </>
  );
};

export default CaseStudiesPage;
